import { useSelector } from 'react-redux';
import fm from 'format-message';
import PropTypes from 'prop-types';
import ScrollableBox from '@activebrands/core-web/components/ScrollableBox';
import overlay from '@activebrands/core-web/libs/overlay';
import { styled } from '@activebrands/core-web/libs/styletron';
import transformProductCardMiniData from '@activebrands/core-web/utils/product/transform-product-card-mini-data';
import BasketUpsell from 'components/Header/BasketOverlay/BasketUpsell';
import Summary from 'components/Header/BasketOverlay/Summary';
import ProductCardMini from 'components/product/ProductCardMini';

const ItemWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'auto',
    position: 'relative',
    justifyContent: 'space-between',
    height: 'calc(var(--vh) - var(--height-header) - 112px)',
    paddingBottom: '12px',
});

const Divider = styled('div', {
    height: '1px',
    backgroundColor: 'var(--color-border-basket)',
    width: '100%',
});

const Basket = ({ content = {}, upsellProducts = [] }) => {
    const items = useSelector(state => state.basket.items);
    const totals = useSelector(state => state.basket.totals);

    const upsellLabel = content.upsellLabel;
    const showProducts = items?.length > 0;

    const productsTotals = [
        {
            label: fm('Subtotal'),
            total: totals?.productsSum,
        },
    ];

    return (
        <ItemWrapper>
            <ScrollableBox
                withScrollbar
                $style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between' }}
            >
                {showProducts && (
                    <div>
                        {items?.map(item => {
                            const transformedItem = transformProductCardMiniData({ product: item, source: 'Centra' });
                            return (
                                <>
                                    <ProductCardMini
                                        $style={{ padding: '12px' }}
                                        key={item.id}
                                        trackingList="Basket Products"
                                        type="basket"
                                        {...transformedItem}
                                        media={transformedItem.media?.[0] ?? {}}
                                        onClick={() => {
                                            overlay.close('basket');
                                        }}
                                    />
                                    <Divider />
                                </>
                            );
                        })}
                    </div>
                )}
                <BasketUpsell heading={upsellLabel} products={upsellProducts} />
            </ScrollableBox>
            <Summary overlay={overlay} totals={productsTotals} />
        </ItemWrapper>
    );
};

Basket.propTypes = {
    content: PropTypes.object,
    upsellProducts: PropTypes.array,
};

export default Basket;
