import fm from 'format-message';
import PropTypes from 'prop-types';
import useCheckoutUrlQuery from 'queries/useCheckoutUrlQuery';
import Link from '@activebrands/core-web/components/Link';
import media from '@activebrands/core-web/config/media';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import ThemeButton from 'components/buttons/ThemeButton';
import Paragraph from 'components/text/Paragraph';

const SummaryWrapper = styled('div', {
    width: '100%',
    borderTop: '1px solid var(--color-border-basket)',
    backgroundColor: 'var(--color-bg-header)',

    [media.min['mobile.lg']]: {
        marginBottom: 0,
    },
});

const Subtotal = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 12px 12px 12px',
});

const Summary = ({ totals, overlay }) => {
    const [css] = useStyletron();
    const { checkoutPage } = useCheckoutUrlQuery();

    const closeOverlay = () => {
        overlay.close('basket');
    };

    return (
        <SummaryWrapper>
            {totals.map(item => (
                <Subtotal key={item.label}>
                    <Paragraph fontKeys="Misc/20_120_-025">{item.label}:</Paragraph>
                    <Paragraph fontKeys="Misc/20_120_-025">{item.total}</Paragraph>
                </Subtotal>
            ))}
            <div className={css({ padding: '12px 12px 0 12px' })} onClick={closeOverlay}>
                <ThemeButton
                    $style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        height: '48px',
                        alignItems: 'center',
                    }}
                    as={Link}
                    fontKeys="Misc/20_120_-025"
                    theme="defaultsecondary"
                    to={checkoutPage}
                >
                    {fm('Go to checkout')}
                </ThemeButton>
            </div>
        </SummaryWrapper>
    );
};

Summary.propTypes = {
    overlay: PropTypes.object.isRequired,
    totals: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string, total: PropTypes.string })).isRequired,
};

export default Summary;
